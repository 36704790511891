<template>
  <div>
    <div class="notfind">
      <img :src="oss + '/manage/media/state/500.png'" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
}
</script>
